@import url("bootstrap/dist/css/bootstrap.min.css");

.form-signin {
  width: 100%;
  max-width: 576px;
  margin: auto;
  padding: 15px;
}

.bi {
  width: 1em;
  height: 1em;
  fill: currentcolor;
  vertical-align: -0.125em;
}

.dropdown-menu {
  z-index: 1021; /* on top of .sticky-top */
}
